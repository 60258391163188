import styled from 'styled-components';

export const Wrapper = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	line-height: 1.33349;
	font-weight: 400;
	letter-spacing: .009em;
	font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
	background-color: white;
	flex-direction: column;
`;

export const Text = styled.div`
	width: 75%;
	max-width: 800px;
	text-align: center;
	padding-top: 60px;
`;

export const SubText = styled.div`
	color: grey;
	font-size: 0.5em;
	padding-top: 100px;
	position: absolute;
	bottom: 10px;
	width: 75%;
	max-width: 800px;
	text-align: center;
`;

export const Blink = styled.img`

	@keyframes blinkblink {
		50% {
			opacity: 0.0;
		}
		100% {
			opacity: 1;
		}
	}
	animation: blinkblink 2s step-start 1s infinite;
	width: 80px;
`;

import React, { Component } from 'react';
import { Wrapper, Blink, Text, SubText } from './components';
import missing from './missing.png';

class App extends Component {
  render() {
    return (
      <Wrapper>
        <Blink src={missing} />

        <Text>
          Introducing the AirPower mat. Just set your iPhone, Apple Watch, and AirPods anywhere on the mat to charge them wirelessly.
        </Text>
        <SubText>
            Sold separately. Requires compatible iPhone, Apple Watch, or AirPods. AirPods with wireless charging capabilities coming in 2018. Or not, you'll have to find out :)
        </SubText>
      </Wrapper>
    );
  }
}

export default App;
